// TestimonialBox.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from '../../page.module.css';
import '../../../globals.css';

interface TestimonialBoxProps {
  text: string;
  name: string;
  position: string;
}

const TestimonialBox: React.FC<TestimonialBoxProps> = ({ text, name, position }) => {
  return (
    <Box className="testimonialBox">
      <Typography className="testimonialContent">{text}</Typography>
      <Box mt={2}>
        <Typography className="testimonialName">{name}</Typography>
        <Typography className="testimonialPositiion">{position}</Typography>
      </Box>
    </Box>
  );
};

export default TestimonialBox;

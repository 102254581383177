"use client"
import '../../../globals.css';
import Image from 'next/image';
import AwardCards from './awardCards';
import Testimonial from './testimonial';
import FadeUp from '@/lib/dataAos/fadeUp';
import { getDictionary } from '@/dictionaries';
import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import TestimonialMobile from './testimonialMobile';
import WhileUp from '@/lib/framerMotion/whileUp';

const Page = ({ params }: { params: { lang: string } }) => {
  const [dictionary, setDictionary] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchDictionary = async () => {
      try {
        const result = await getDictionary(params.lang);
        setDictionary(result);
      } catch (error) {
        console.error("Error fetching dictionary:", error);
      }
    };
    fetchDictionary();
  }, [params.lang]);

  return (
    <Box style={{ backgroundColor: '#18576C' }}>
      <Box className="elipseDestop">
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Image
            src="/img/Ellipse11.png"
            width={100}
            height={100}
            layout='responsive'
            alt=""
          />
        </Box>
      </Box>
      <Box className="elipseMobile">
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Image
            src="/img/EllipseAwardMobile.png"
            width={100}
            height={100}
            layout='responsive'
            alt=""
          />
        </Box>
      </Box>
      <Box className="awardContainer">
        <WhileUp duration={0.7}>
          <Typography className="titleAward">
            {dictionary.titleAward1}
          </Typography>
        </WhileUp>
        {/* <FadeUp duration='700'>
        </FadeUp>
        <FadeUp duration='700'>
        </FadeUp> */}
        <WhileUp duration={0.7}>
          <Typography className="subTitleAward">
            {dictionary.titleAward2}
          </Typography>
        </WhileUp>
        <Box className="awardBoxContainer">
          <AwardCards />
        </Box>
      </Box>
      <Box sx={{ position: 'relative', zIndex: 4 }}>
        <WhileUp duration={0.7}>
          <Typography className='titleTestimonial'>
            {dictionary.titleAward3}
          </Typography>
        </WhileUp>
        {/* <FadeUp duration='700'>
        </FadeUp> */}
        <Box className="testimonial">
          <Testimonial params={{ lang: params.lang }} />
        </Box>
        <Box className="testimonialMobile">
          <TestimonialMobile />
        </Box>
      </Box>
      <Box className="awardGradient">
        <img
          src='/img/awardBgGradient.png'
          style={{ width: '100%', height: '100%' }}
          alt='gradient'
        />
      </Box>
    </Box>
  )
}

export default Page

export const awardData = [
  {
    id: '1',
    image: '/img/awards1-Copy.png',
    content: 'Winner of the most HPTL Excise Ribbon takers in 2021 Banten Regional Office'
  },
  {
    id: '2',
    image: '/img/awards2-Copy.png',
    content: 'Award Certificate for handling Covid-19 in 2020 from Balikpapan Regional Office.'
  },
  {
    id: '3',
    image: '/img/awards3.png',
    content: 'Best Brand at Sidoarjo Vape Expo 2019.'
  },
  {
    id: '4',
    image: '/img/awards4.png',
    content: 'certificate of contribution to 2018 state revenues'
  },
  {
    id: '5',
    image: '/img/awards5.png',
    content: 'Best Creamy Liquid at Sidoarjo Vape Expo 2023'
  },
  {
    id: '6',
    image: '/img/awards6.jpg',
    content: 'ISO 9001:2015 certificate'
  },
]


export const testimonialData = [
  {
    id: '1',
    profile: '/img/komo.jpg',
    content: 'Enak, simple, gak bocor, gak ribet, gak ada ruginya deh pokoknya kalo pake E-Type!',
    name: 'Komo',
    position: 'Actor, Host'
  },
  {
    id: '2',
    profile: '/img/khalid.jpg',
    content: 'Penting banget buat lo punya sesuatu yang bisa lo percaya dan bisa di andalkan setiap hari.Salah satunya buat gue, E-type.',
    name: 'Khalid Ibrahim',
    position: 'Digital Creator'
  },
  {
    id: '3',
    profile: '/img/testimoni1.jpg',
    content: 'Hidup gue bener-bener jadi stress-free dan hassle-free, thanks to EMKAY',
    name: 'Prita Prawihardjo',
    position: 'Group Music Director - MRA Broadcast Media'
  },
  {
    id: '4',
    profile: '/img/niswa.jpg',
    content: 'Rasa yang ga pernah berubah, bikin ngejaga mood aku dimana pun dalam kondisi apapun dan Pod E-type yang selalu nyaman di bawa kemana mana.',
    name: 'Iwa',
    position: 'Model'
  },
  {
    id: '5',
    profile: '/img/profile.png',
    content: ' Pilihan terbaik. Rasa luar biasa, kualitas terbaik. Pengalaman vaping yang sempurna',
    name: 'Kristianto',
    position: 'Karyawan Swasta'
  },
];
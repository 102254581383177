"use client"
import React, { useState, useEffect } from 'react'
import Image from 'next/image';
import Slider from "react-slick";
import { Box } from "@mui/material";
import { testimonialData } from './data';
import ProfileImages from './profileImage';
import TestimonialBox from './testimonialBox';

interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

function NextArrow(props: ArrowProps) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#fff",
        marginLeft: "2px",
        padding: "15px",
        zIndex: 99,
        borderRadius: "100%",
        boxShadow: " 0px 8px 22px 0px rgba(179, 179, 208, 0.30)",
        opacity: "50%"
      }}
      onClick={onClick}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <Image src="/img/arrowNext.png" width={8} height={14} alt='' />
      </div>
    </div>
  );
}

function PrevArrow(props: ArrowProps) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#fff",
        marginLeft: "2px",
        padding: "15px",
        zIndex: 99,
        borderRadius: "100%",
        boxShadow: " 0px 8px 22px 0px rgba(179, 179, 208, 0.30)",
        opacity: "50%"
      }}
      onClick={onClick}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <Image src="/img/arrowPrev.png" width={8} height={14} alt='' />
      </div>
    </div>
  );
}

const TestimonialMobile = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // console.log(windowSize.width)
  return (
    <Box sx={{ margin: ' 0 20px', padding: '0 10px' }}>
      <Slider {...settings}>
        {testimonialData?.map((item) => (
          <Box key={item.id}>
            <Box sx={{ marginBottom: '-35px' }}>
              <ProfileImages ellipseSrc="/img/Ellipse493.png" profileSrc={item.profile} />
            </Box>
            <Box
              sx={{
                width: windowSize.width < 385 ? '245px' : '275px',
                marginLeft: '2px',
                height: '198px',
                backgroundColor: '#333333',
                borderRadius: '24px 4px 24px 4px',
                padding: '48px 26px 16px 26px'
              }}
            >
              <TestimonialBox
                text={item.content}
                name={item.name}
                position={item.position}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  marginTop: '-50px'
                }}
              >
                <Box
                  sx={{
                    width: '32px',
                    height: '30px',
                    overflow: 'hidden',
                  }}
                >
                  <img src="/img/quoteDuo.png" alt="" style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }} />
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  )
}

export default TestimonialMobile

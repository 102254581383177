import Image from 'next/image';
import React from 'react';
import { Box } from '@mui/material';

interface ProfileImagesProps {
  ellipseSrc: string;
  profileSrc: string;
}

const ProfileImages: React.FC<ProfileImagesProps> = ({ ellipseSrc, profileSrc }) => {
  return (
    <Box className="profileImages">
      <Box className="ellipseImage">
        <img src={ellipseSrc} alt="" />
      </Box>
      <Box className="profileImage">
        <img src={profileSrc} alt="" />
      </Box>
    </Box>
  );
};

export default ProfileImages;

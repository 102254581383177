"use client"
import React, { useEffect } from "react";
import '../../../globals.css';
import { awardData } from './data';
import { Box } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, { Settings } from "react-slick";

const awardCards = () => {

  const settings: Settings = {
    speed: 3000,
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 3,
    nextArrow: <></>,
    prevArrow: <></>,
    slidesToScroll: 1,
    cssEase: "linear",
    variableWidth: true,
    autoplaySpeed: 1000,
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }]
  };
  return (
    <div style={{ margin: '40px 0' }}>
      <Slider {...settings}>
        {awardData?.map((item, index) => (
          <Box key={item.id} className="cardAwardContainer">
            <Box className={index % 2 === 0 ? "cardAwardEnd" : "cardAwardStart"}>
              <Box className="boxImageCardAward" sx={{ padding: '0 15px' }}>
                <img src={item.image} alt="" />
                <div className="overlay">
                  <div className="textOverlay">{item.content}</div>
                </div>
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>
    </div>
  )
}

export default awardCards
"use client"
import React, { useEffect, useState } from 'react'
import styles from '../../page.module.css';
import ProfileImages from './profileImage';
import TestimonialBox from './testimonialBox';
import FadeLeft from '@/lib/dataAos/fadeLeftTesti';
import FadeRight from '@/lib/dataAos/fadeRight';
import { Grid, Box, Typography } from '@mui/material';
// import { testimonialData } from './data';
import { getDictionary } from '@/dictionaries';
import WhileRight from '@/lib/framerMotion/whileRight';
import WhileLeft from '@/lib/framerMotion/whileLeft';

const Testimonial = ({ params }: { params: { lang: string } }) => {
  const [dictionary, setDictionary] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchDictionary = async () => {
      try {
        const result = await getDictionary(params.lang);
        setDictionary(result);
      } catch (error) {
        console.error("Error fetching dictionary:", error);
      }
    };
    fetchDictionary();
  }, [params.lang]);
  const testimonialData = [
    {
      id: '1',
      profile: '/img/komo.jpg',
      content: dictionary.contentTestimonial1,
      name: 'Komo',
      position: 'Actor, Host'
    },
    {
      id: '2',
      profile: '/img/khalid.jpg',
      content: dictionary.contentTestimonial2,
      name: 'Khalid Ibrahim',
      position: 'Digital Creator'
    },
    {
      id: '3',
      profile: '/img/testimoni1.jpg',
      content: dictionary.contentTestimonial3,
      name: 'Prita Prawihardjo',
      position: 'Group Music Director - MRA Broadcast Media'
    },
    {
      id: '4',
      profile: '/img/niswa.jpg',
      content: dictionary.contentTestimonial4,
      name: 'Iwa',
      position: 'Model'
    },
    {
      id: '5',
      profile: '/img/profile.png',
      content: dictionary.contentTestimonial5,
      name: 'Kristianto',
      position: 'Karyawan Swasta'
    },
  ];
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1} sx={{ overflow: 'hidden' }}>

        {testimonialData?.map((item, index) => (
          <Grid
            key={item.id}
            item
            xs={12}
            lg={6}
            className={index % 2 === 0 ? styles.testimonialBoxContainerStart : styles.testimonialBoxContainerEnd}
          >
            {index % 2 === 0 ? (
              <Box>
                <WhileRight duration={0.9}>
                  <Box className={styles.testimonialBoxRow}>
                    <TestimonialBox text={item.content} name={item.name} position={item.position} />
                  </Box>
                  <Box className={styles.profileImagesContainer}>
                    <ProfileImages ellipseSrc="/img/Ellipse493.png" profileSrc={item.profile} />
                    <Box className={styles.doubleQuote}>
                      <img src="/img/quoteDuo.png" alt="" />
                    </Box>
                  </Box>
                </WhileRight>
                {/* <FadeRight duration="700">
                </FadeRight> */}
              </Box>
            ) : (
              <WhileLeft duration={0.9}>
                <Box className={styles.testimonialBoxRow}>
                  <TestimonialBox text={item.content} name={item.name} position={item.position} />
                </Box>
                <Box className={styles.profileImagesContainer}>
                  <ProfileImages ellipseSrc="/img/Ellipse493.png" profileSrc={item.profile} />
                  <Box className={styles.doubleQuote}>
                    <img src="/img/quoteDuo.png" alt="" />
                  </Box>
                </Box>
              </WhileLeft>
              // <FadeLeft duration="700">
              // </FadeLeft>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Testimonial
